<div fxLayout="column">
  <sym-page-header [title]="'Dashboard'"></sym-page-header>

  <div fxFlex>
    <mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeTab($event)">
      <mat-tab label="New Applications" *ngIf="isSuperUser || isAdminUser">
        <sym-content-card>
          <form fxFlex="100" [formGroup]="searchForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
              <mat-form-field fxFlex="35" appearance="outline" style="font-size: small;padding-right: 1em;">
                <mat-label>Search</mat-label>
                <input matInput formControlName="searchString" #input>
              </mat-form-field>

              <mat-form-field fxFlex="15" style="font-size: small;padding-right: 1em;" appearance="outline">
                <mat-label>Select Application Status</mat-label>
                <mat-select formControlName="applicationStatus" placeholder="Select Status">
                  <mat-option *ngFor="let values of statusType let i = index;" [value]="values">
                    {{ values }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="15" style="font-size: small;padding-right: 1em;" appearance="outline">
                <mat-label>Select Service</mat-label>
                <mat-select formControlName="service" placeholder="Select Status">
                  <mat-option *ngFor="let values of servicesValuesList let i = index;" [value]="values?.displayValue">
                    {{ values.displayValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button  style="margin-top: -10px;" matTooltip="Initiate search" mat-icon-button
                (click)="search()" *ngIf="searchForm.controls.searchString.value !== '' ||
                searchForm.controls.applicationStatus.value !== '' ||
                searchForm.controls.service.value !== ''" aria-label="Search">
                <mat-icon>search</mat-icon>
              </button>
              <button style="margin-top: -10px;" *ngIf="searchForm.controls.searchString.value !== '' ||
                searchForm.controls.applicationStatus.value !== '' ||
                searchForm.controls.service.value !== ''" matSuffix mat-icon-button (click)="clearCriteria()"><mat-icon class="clear-icon">close</mat-icon></button>
            </div>
          </form>

          <div style="padding: 2em;">
            <mat-divider style="width: 92%;left:2%"></mat-divider>
          </div>
          <sym-non-blocking class="non-blocking-loader" *ngIf="loadingservice.showNonBlockingLoader"></sym-non-blocking>
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="dateReceived">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="11">
              Date Received
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="11">
              <div fxLayout="column">
                <div *ngIf="element.applicationDetails.dateReceived">
                  {{
                  element.applicationDetails.dateReceived|date:'dd/MM/yyyy'

                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assessmentStatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12">
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="12">
              <div fxLayout="column">
                <div *ngIf="element.assessmentStatus">
                  {{
                  element.assessmentStatus

                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="companyName">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10">
              Company </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{element.applicationDetails.company}}" fxFlex="13" matTooltipPosition="above">
              <div fxLayout="column">
                <div>
                  {{
                  element.applicationDetails.company.length > 9
                  ? (element.applicationDetails.company | slice: 0:9) + " ..."
                  : element.applicationDetails.company
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="service">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="13">Service
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="13"
              matTooltip="{{ element.applicationDetails.servicesRequired | uppercase}}" matTooltipPosition="above">
              <div fxLayout="column">
                <div *ngIf="element.applicationDetails.servicesRequired">
                  {{
                  element.applicationDetails.servicesRequired.length > 25
                  ? (element.applicationDetails.servicesRequired | slice: 0:25) + " ..."
                  : element.applicationDetails.servicesRequired
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="originatingOffice">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10"> Office </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltipPosition="above" fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.originatingOffice">
                  {{
                  element.originatingOffice
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedAdmin">
            <mat-header-cell *matHeaderCellDef fxFlex="11">
              Admin
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{ element.verificationTeam.administrator | uppercase}}"
              matTooltipPosition="above" fxFlex="11">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.administrator">
                  {{
                  element.verificationTeam.administrator.length > 10
                  ? (element.verificationTeam.administrator | slice: 0:12) + " ..."
                  : element.verificationTeam.administrator || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedAnalyst">
            <mat-header-cell *matHeaderCellDef fxFlex="7">
              Analyst
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{ element.verificationTeam.analyst | uppercase}}"
              matTooltipPosition="above" fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.analyst">
                  {{
                  element.verificationTeam.analyst.length > 10
                  ? (element.verificationTeam.analyst | slice: 0:12) + " ..."
                  : element.verificationTeam.analyst || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedTechnicalSignatory">
            <mat-header-cell *matHeaderCellDef fxFlex="10">
              T/S
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
              matTooltip="{{ element.verificationTeam.technicalSignatory | uppercase}}" matTooltipPosition="above"
              fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.technicalSignatory">
                  {{
                  element.verificationTeam.technicalSignatory.length > 10
                  ? (element.verificationTeam.technicalSignatory | slice: 0:12) + " ..."
                  :element.verificationTeam.technicalSignatory || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit" >
            <mat-header-cell *matHeaderCellDef fxFlex="16"> </mat-header-cell>
            <mat-cell fxLayout="row" fxLayoutAlign="center center" *matCellDef="let element" fxFlex="16">
              <button matTooltipPosition="left" matTooltip="View Application"
                (click)="viewApplicationClick(element.applicationDetails.company,element.id)" mat-icon-button
                color="primary" class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">visibility</mat-icon>
              </button>

              <button matTooltipPosition="left" matTooltip="Manage Application "
                (click)="manageApplicationClick(element.applicationDetails.company,element.id,element.applicationDetails.id)"
                mat-icon-button color="primary" class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">edit</mat-icon>
              </button>

              <button matTooltipPosition="left" matTooltip="Allocate Team"
                (click)="allocateTeam(element.id)" mat-icon-button color="primary"
                class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">person_add</mat-icon>
              </button>
              <!-- class="mobile-hidden mat-light-green-700-bg" *ngIf="this.tabIndex === 0"> -->

            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10]">
        </mat-paginator>
        </sym-content-card>
        
        
      </mat-tab>


      <mat-tab label="My Applications">
        <sym-content-card>
          <form fxFlex="100" [formGroup]="searchForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
              <mat-form-field fxFlex="35" appearance="outline" style="font-size: small;padding-right: 1em;">
                <mat-label>Search</mat-label>
                <input matInput formControlName="searchString" #input>
              </mat-form-field>

              <mat-form-field fxFlex="15" style="font-size: small;padding-right: 1em;" appearance="outline">
                <mat-label>Select Application Status</mat-label>
                <mat-select formControlName="applicationStatus" placeholder="Select Status">
                  <mat-option *ngFor="let values of statusType let i = index;" [value]="values">
                    {{ values }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="15" style="font-size: small;padding-right: 1em;" appearance="outline">
                <mat-label>Select Service</mat-label>
                <mat-select formControlName="service" placeholder="Select Status">
                  <mat-option *ngFor="let values of servicesValuesList let i = index;" [value]="values?.displayValue">
                    {{ values.displayValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button  style="margin-top: -10px;" matTooltip="Initiate search" mat-icon-button
                (click)="search()" *ngIf="searchForm.controls.searchString.value !== '' ||
                searchForm.controls.applicationStatus.value !== '' ||
                searchForm.controls.service.value !== ''" aria-label="Search">
                <mat-icon>search</mat-icon>
              </button>
              <button style="margin-top: -10px;" *ngIf="searchForm.controls.searchString.value !== '' ||
                searchForm.controls.applicationStatus.value !== '' ||
                searchForm.controls.service.value !== ''" matSuffix mat-icon-button (click)="clearCriteria()"><mat-icon class="clear-icon">close</mat-icon></button>
            </div>
          </form>

          <div style="padding: 2em;">
            <mat-divider style="width: 92%;left:2%"></mat-divider>
          </div>
          <sym-non-blocking class="non-blocking-loader" *ngIf="loadingservice.showNonBlockingLoader"></sym-non-blocking>
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="dateReceived">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="11">
              Date Received
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="11">
              <div fxLayout="column">
                <div *ngIf="element.applicationDetails.dateReceived">
                  {{
                  element.applicationDetails.dateReceived|date:'dd/MM/yyyy'

                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assessmentStatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12">
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="12">
              <div fxLayout="column">
                <div *ngIf="element.assessmentStatus">
                  {{
                  element.assessmentStatus

                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="companyName">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10">
              Company </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{element.applicationDetails.company}}" fxFlex="13" matTooltipPosition="above">
              <div fxLayout="column">
                <div>
                  {{
                  element.applicationDetails.company.length > 9
                  ? (element.applicationDetails.company | slice: 0:9) + " ..."
                  : element.applicationDetails.company
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="service">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="13">Service
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="13"
              matTooltip="{{ element.applicationDetails.servicesRequired | uppercase}}" matTooltipPosition="above">
              <div fxLayout="column">
                <div *ngIf="element.applicationDetails.servicesRequired">
                  {{
                  element.applicationDetails.servicesRequired.length > 25
                  ? (element.applicationDetails.servicesRequired | slice: 0:25) + " ..."
                  : element.applicationDetails.servicesRequired
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="originatingOffice">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10"> Office </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltipPosition="above" fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.originatingOffice">
                  {{
                  element.originatingOffice
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedAdmin">
            <mat-header-cell *matHeaderCellDef fxFlex="11">
              Admin
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{ element.verificationTeam.administrator | uppercase}}"
              matTooltipPosition="above" fxFlex="11">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.administrator">
                  {{
                  element.verificationTeam.administrator.length > 10
                  ? (element.verificationTeam.administrator | slice: 0:12) + " ..."
                  : element.verificationTeam.administrator || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedAnalyst">
            <mat-header-cell *matHeaderCellDef fxFlex="7">
              Analyst
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{ element.verificationTeam.analyst | uppercase}}"
              matTooltipPosition="above" fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.analyst">
                  {{
                  element.verificationTeam.analyst.length > 10
                  ? (element.verificationTeam.analyst | slice: 0:12) + " ..."
                  : element.verificationTeam.analyst || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedTechnicalSignatory">
            <mat-header-cell *matHeaderCellDef fxFlex="10">
              T/S
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
              matTooltip="{{ element.verificationTeam.technicalSignatory | uppercase}}" matTooltipPosition="above"
              fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.technicalSignatory">
                  {{
                  element.verificationTeam.technicalSignatory.length > 10
                  ? (element.verificationTeam.technicalSignatory | slice: 0:12) + " ..."
                  :element.verificationTeam.technicalSignatory || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit" >
            <mat-header-cell *matHeaderCellDef fxFlex="16"> </mat-header-cell>
            <mat-cell fxLayout="row" fxLayoutAlign="center center" *matCellDef="let element" fxFlex="16">
              <button matTooltipPosition="left" matTooltip="View Application"
                (click)="viewApplicationClick(element.applicationDetails.company,element.id)" mat-icon-button
                color="primary" class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">visibility</mat-icon>
              </button>

              <button matTooltipPosition="left" matTooltip="Manage Application "
                (click)="manageApplicationClick(element.applicationDetails.company,element.id,element.applicationDetails.id)"
                mat-icon-button color="primary" class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">edit</mat-icon>
              </button>

              <button matTooltipPosition="left" matTooltip="Allocate Team"
                (click)="allocateTeam(element.id)" mat-icon-button color="primary"
                class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">person_add</mat-icon>
              </button>
              <!-- class="mobile-hidden mat-light-green-700-bg" *ngIf="this.tabIndex === 0"> -->

            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #myPaginator  [pageSizeOptions]="[10]">
        </mat-paginator>
        </sym-content-card>
      </mat-tab>

      <mat-tab label="All Applications" *ngIf="isSuperUser || isAdminUser">
        <sym-content-card>
          <form fxFlex="100" [formGroup]="searchForm">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
              <mat-form-field fxFlex="35" appearance="outline" style="font-size: small;padding-right: 1em;">
                <mat-label>Search</mat-label>
                <input matInput formControlName="searchString" #input>
              </mat-form-field>

              <mat-form-field fxFlex="15" style="font-size: small;padding-right: 1em;" appearance="outline">
                <mat-label>Select Application Status</mat-label>
                <mat-select formControlName="applicationStatus" placeholder="Select Status">
                  <mat-option *ngFor="let values of statusType let i = index;" [value]="values">
                    {{ values }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="15" style="font-size: small;padding-right: 1em;" appearance="outline">
                <mat-label>Select Service</mat-label>
                <mat-select formControlName="service" placeholder="Select Status">
                  <mat-option *ngFor="let values of servicesValuesList let i = index;" [value]="values?.displayValue">
                    {{ values.displayValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button  style="margin-top: -10px;" matTooltip="Initiate search" mat-icon-button
                (click)="search()" *ngIf="searchForm.controls.searchString.value !== '' ||
                searchForm.controls.applicationStatus.value !== '' ||
                searchForm.controls.service.value !== ''" aria-label="Search">
                <mat-icon>search</mat-icon>
              </button>
              <button style="margin-top: -10px;" *ngIf="searchForm.controls.searchString.value !== '' ||
                searchForm.controls.applicationStatus.value !== '' ||
                searchForm.controls.service.value !== ''" matSuffix mat-icon-button (click)="clearCriteria()"><mat-icon class="clear-icon">close</mat-icon></button>
            </div>
          </form>

          <div style="padding: 2em;">
            <mat-divider style="width: 92%;left:2%"></mat-divider>
          </div>
          <sym-non-blocking class="non-blocking-loader" *ngIf="loadingservice.showNonBlockingLoader"></sym-non-blocking>
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
          <ng-container matColumnDef="dateReceived">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="11">
              Date Received
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="11">
              <div fxLayout="column">
                <div *ngIf="element.applicationDetails.dateReceived">
                  {{
                  element.applicationDetails.dateReceived|date:'dd/MM/yyyy'

                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assessmentStatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="12">
              Status
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="12">
              <div fxLayout="column">
                <div *ngIf="element.assessmentStatus">
                  {{
                  element.assessmentStatus

                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="companyName">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10">
              Company </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{element.applicationDetails.company}}" fxFlex="13" matTooltipPosition="above">
              <div fxLayout="column">
                <div>
                  {{
                  element.applicationDetails.company.length > 9
                  ? (element.applicationDetails.company | slice: 0:9) + " ..."
                  : element.applicationDetails.company
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="service">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="13">Service
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="13"
              matTooltip="{{ element.applicationDetails.servicesRequired | uppercase}}" matTooltipPosition="above">
              <div fxLayout="column">
                <div *ngIf="element.applicationDetails.servicesRequired">
                  {{
                  element.applicationDetails.servicesRequired.length > 25
                  ? (element.applicationDetails.servicesRequired | slice: 0:25) + " ..."
                  : element.applicationDetails.servicesRequired
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="originatingOffice">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10"> Office </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltipPosition="above" fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.originatingOffice">
                  {{
                  element.originatingOffice
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="assignedAdmin">
            <mat-header-cell *matHeaderCellDef fxFlex="11">
              Admin
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{ element.verificationTeam.administrator | uppercase}}"
              matTooltipPosition="above" fxFlex="11">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.administrator">
                  {{
                  element.verificationTeam.administrator.length > 10
                  ? (element.verificationTeam.administrator | slice: 0:12) + " ..."
                  : element.verificationTeam.administrator || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedAnalyst">
            <mat-header-cell *matHeaderCellDef fxFlex="7">
              Analyst
            </mat-header-cell>
            <mat-cell *matCellDef="let element" matTooltip="{{ element.verificationTeam.analyst | uppercase}}"
              matTooltipPosition="above" fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.analyst">
                  {{
                  element.verificationTeam.analyst.length > 10
                  ? (element.verificationTeam.analyst | slice: 0:12) + " ..."
                  : element.verificationTeam.analyst || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="assignedTechnicalSignatory">
            <mat-header-cell *matHeaderCellDef fxFlex="10">
              T/S
            </mat-header-cell>
            <mat-cell *matCellDef="let element"
              matTooltip="{{ element.verificationTeam.technicalSignatory | uppercase}}" matTooltipPosition="above"
              fxFlex="10">
              <div fxLayout="column">
                <div *ngIf="element.verificationTeam.technicalSignatory">
                  {{
                  element.verificationTeam.technicalSignatory.length > 10
                  ? (element.verificationTeam.technicalSignatory | slice: 0:12) + " ..."
                  :element.verificationTeam.technicalSignatory || 'Unassigned'
                  }}
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit" >
            <mat-header-cell *matHeaderCellDef fxFlex="16"> </mat-header-cell>
            <mat-cell fxLayout="row" fxLayoutAlign="center center" *matCellDef="let element" fxFlex="16">
              <button matTooltipPosition="left" matTooltip="View Application"
                (click)="viewApplicationClick(element.applicationDetails.company,element.id)" mat-icon-button
                color="primary" class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">visibility</mat-icon>
              </button>

              <button matTooltipPosition="left" matTooltip="Manage Application "
                (click)="manageApplicationClick(element.applicationDetails.company,element.id,element.applicationDetails.id)"
                mat-icon-button color="primary" class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">edit</mat-icon>
              </button>

              <button matTooltipPosition="left" matTooltip="Allocate Team"
                (click)="allocateTeam(element.id)" mat-icon-button color="primary"
                class="mobile-hidden mat-light-green-700-bg">
                <mat-icon style="color: #0D2430;">person_add</mat-icon>
              </button>
              <!-- class="mobile-hidden mat-light-green-700-bg" *ngIf="this.tabIndex === 0"> -->

            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator #allPaginator [pageSizeOptions]="[10]">
        </mat-paginator>
        </sym-content-card>
      </mat-tab>

    </mat-tab-group>
  </div>
</div>