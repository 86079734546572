<mat-toolbar #header style="border-radius: 0 0 15px 15px;padding-top: 5px;" [color]="color" tlvtHeader [fixed]="fixed"
    [headerHeight]='headerHeight' fxLayoutAlign="space-between center">
    <div class="logo-area" fxLayout="row" fxLayoutAlign="space-between center">
        <img class="logo" [src]="logo" alt="">       
    </div>
    <div class="profile">
        <sym-profile-summary></sym-profile-summary>
    </div>
</mat-toolbar>
<sym-non-blocking class="non-blocking-loader" *ngIf="loadingService.showNonBlockingLoader"></sym-non-blocking>
