<div class="white-bg mat-elevation-z2 m-8" >
    <div class="background" fxLayoutAlign="space-between center" fxLayout="row wrap">
      <div style="margin-left: 5px;">
        {{name}}
      </div>
      <button *ngIf="hasButton" type="button" mat-icon-button (click)="onButtonClicked()">
        <mat-icon class="white-fg">{{buttonIcon}}</mat-icon>
      </button>
    </div>
  
    <div class="p-12">
      <ng-content #body>
      </ng-content>
    </div>
  </div>