<!-- <button *ngIf="icon != 'delete'" style="height: 30px !important; width: 30px !important;" [matTooltip]="tooltip"
  type="button" mat-mini-fab (click)="onButtonClicked()">

  <!-- <mat-icon *ngIf="icon == 'delete'" style="height: 30px !important; width: inherit !important; font-size: medium"></mat-icon> -->
<!-- </button> -->
<mat-icon *ngIf="icon != 'delete'" style="height: 20px !important; width: 30px !important; font-size: xx-large"
  (click)="onButtonClicked()">
  {{icon}}</mat-icon>

<a *ngIf="icon == 'delete'" [matTooltip]="tooltip" type="button" (click)="onButtonClicked()">
  <img src="./assets/Elements/Elements/delete.svg" *ngIf="icon == 'delete'">
</a>