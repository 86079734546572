import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LayoutService {
  /**
   * Subject containing the state of the sidenav
   */
  public sideNavState$: Subject<boolean> = new Subject();
  /**
   * Boolean parameter for expanded state of sidenav
   */
  public expanded = false;
  /**
   * Boolean value indicating loading state
   */
  public isLoading = false;
  /**
   * Boolean value indicating alternative loading state
   */
  public isBusy = false;

  /**
   * Configuration parameters for layout
   */
  config = {
    header: {
      headerHeight: 132, // Provide height incremetals of 4px's
      logo: './assets/logos/tlvtMassive.png', // provide url or local reference to logo
      buttonIcon: 'menu',  // Provide a button icon (displayed right)
      fixed: 'true', // String value true or false
      backgroundColor: 'none' //
      // backgroundColor: '#FCFCFC'
    },
    content: {
      top: 40
    },
    sidenav: {
      expanded: true
    }
  };
  expandedSidenav = false;

  /**
   * Instantiates the LayoutService module
   */
  constructor() { }
}
