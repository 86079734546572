import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sym-forgot-password-reset',
  templateUrl: './forgot-password-reset.component.html',
  styleUrls: ['./forgot-password-reset.component.scss']
})
export class ForgotPasswordResetComponent implements OnInit {
  apiEndpoint = environment.API.userManagement;
  imageSrc = '../assets/logos/tlvtfull.png';

  constructor() { }

  ngOnInit(): void {
  }

}
