<sym-card-dialog [title]="'Assign Application'" [closeMethod]="closeDialog">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-hint>Please remember to complete the declaration form by accessing the declaration tab</mat-hint>
    </div>
    <form name="officeForm" [formGroup]="officeForm" class="compose-form" fxLayout="column" fxFlex class=" pt-4 m-8">
      <mat-form-field color="accent" class="full-width" appearance="outline" style="font-size: small;">
        <mat-label>Select Originating Office</mat-label>
        <mat-select formControlName="originatingOffice" color="accent">
          <mat-option *ngFor="let office of lookupService.applicationLookupData.branch" [value]="office">
            {{office}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="officeForm.get('originatingOffice').hasError('required')">
          * Required
        </mat-error>
      </mat-form-field>
    </form>
    <mat-accordion>
      <form [formGroup]="allocateForm">
        <mat-expansion-panel class="m-8">
          <mat-expansion-panel-header class="h-40 mat-elevation-z4">
            <mat-panel-title>
              Technical Signatory
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form formGroupName="tsForm" class="compose-form" fxLayout="column" fxFlex>
            <div mat-dialog-content class="pt-4 m-16" perfect-scrollbar>
              <mat-form-field color="accent" class="full-width" appearance="outline" style="font-size: small;">
                <mat-label>Allocate this assessment to</mat-label>
                <mat-select formControlName="tsAssignTo" color="accent"  >
                  <mat-option *ngFor="let team of allocatableUsers" [value]="team">
                    {{team}}
                  </mat-option>
                </mat-select>
                <div matSuffix *ngIf="allocatableUsers === null">
                  <mat-spinner diameter="24"></mat-spinner>
                </div>
              </mat-form-field>
              <div fxLayout="row" fxLayoutGap="12px">
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Set assessment role</mat-label>
                    <mat-select class="full-width" [value]="allocateForm.controls.tsForm.value.tsAssesementRole"
                      formControlName="tsAssesementRole" color="accent">
                      <mat-option *ngFor="let tech of ts" [value]='tech.viewValue'>
                        {{tech.viewValue}}</mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
        <mat-expansion-panel class="m-8">
          <mat-expansion-panel-header class="h-40 mat-elevation-z4">
            <mat-panel-title>
              Analyst
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div mat-dialog-content class="pt-4 m-16" perfect-scrollbar>
            <form  formGroupName="analystForm" class="compose-form" fxLayout="column" fxFlex>
              <mat-form-field color="accent" class="full-width" appearance="outline" style="font-size: small;">
                <mat-label>Allocate this assessment to</mat-label>
                <mat-select formControlName="analystAssignTo" color="accent" >
                  <mat-option *ngFor="let team of allocatableUsers" [value]="team">
                    {{team}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Set assessment role</mat-label>
                  <mat-select class="full-width" [value]="allocateForm.controls.analystForm.value.analystAssesementRole"
                    formControlName="analystAssesementRole" color="accent">
                    <mat-option *ngFor="let analyst of analysts " [value]='analyst.viewValue'>
                      {{analyst.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
             
            </form>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="m-8">
          <mat-expansion-panel-header class="h-40 mat-elevation-z4">
            <mat-panel-title>
              Administrator
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div mat-dialog-content class="pt-4 m-16" perfect-scrollbar>
            <form  formGroupName="adminForm" class="compose-form" fxLayout="column" fxFlex>
              <mat-form-field color="accent" class="full-width" appearance="outline" style="font-size: small;">
                <mat-label>Allocate this assessment to</mat-label>
                <mat-select formControlName="adminAssignTo" color="accent" >
                  <mat-option *ngFor="let team of allocatableUsers" [value]="team">
                    {{team}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div fxLayout="row" fxLayoutGap="12px">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Set assessment role</mat-label>
                  <mat-select class="full-width" [value]="allocateForm.controls.adminForm.value.adminAssesementRole"
                    formControlName="adminAssesementRole" color="accent">
                    <mat-option *ngFor="let ad of admins " [value]='ad.viewValue'>
                      {{ad.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            
            </form>
          </div>
        </mat-expansion-panel>
      </form>
      <div class="pt-40 pb-40">
        <mat-divider></mat-divider>
      </div>
      <div mat-dialog-actions class="m-0" fxLayout="row" fxLayoutAlign="end center">
        <button matTooltip="save changes" [disabled]="!allocateForm.valid || !officeForm.valid" mat-raised-button
          class="save-button mat-accent" aria-label="Submit" (click)="save()">
          Submit
        </button>
      </div>
    </mat-accordion>
  </div>
</sym-card-dialog>