import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssesmentService {
  applicationDetails: any;
  assesmentApi = environment.API.assesmentManagement;
  commentsApi = environment.API.commentManagement;
  riskApi = environment.API.riskManagement;
  cor04Api = environment.API.cor04;

  constructor(private http: HttpClient) {
  }

  getAllAssesments(index) {
    return this.http.get(this.assesmentApi + '/all?limit=50&start_at=' + index);
  }
  getNewAssesments(index) {
    return this.http.get(this.assesmentApi + '/bystatus/New?limit=50&start_at=' + index);
  }
  getMyAssesments(user, index) {
    return this.http.get(this.assesmentApi + '/assignto/' + user + '?limit=50&start_at=' + index);
  }

  getApplicationDetails(id) {
    return this.http.get(this.assesmentApi + '/byidwithcomments/' + id);
  }
  submitComments(comments) {
    return this.http.post(this.commentsApi + '/all', comments);
  }
  updateAssesment(verificationTeam, id) {
    return this.http.put(this.assesmentApi + '/update/' + id, verificationTeam);
  }

  getRiskAssessment(id) {
    return this.http.get(this.riskApi + id);
  }

  updateRiskAssessment(id, riskAssessment) {
    return this.http.put(this.riskApi + id, riskAssessment);
  }

  getDeclaration(id) {
    return this.http.get(this.assesmentApi + '/declaration/' + id);
  }

  submitDeclaration(id,declaration) {
    return this.http.put(this.assesmentApi + '/declaration/save/' + id, declaration);
  }

  getSearchedAssessement(search) {
    const queryString = this.assesmentApi + '/filter?search=' + search.searchString +
      '&servicesRequired=' + search.service +
      '&assessmentStatus=' + search.applicationStatus
    return this.http.get(queryString);
  }

  getAllAssigneeUsers(){
    return this.http.get(this.assesmentApi + '/assignee')
  }

  getCor04(id) {
    return this.http.get(this.cor04Api + id);
  }

  submitCor04(cor04,id) {
    return this.http.put(this.cor04Api + id, cor04);
  }
}
