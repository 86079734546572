import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommunicationService } from '../communication/communication.service';
import { LoadingService } from '../loading/loading.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from '../../core/services/lookup/lookup.service';
import { Applications } from 'src/app/interfaces/applications';
import * as moment from 'moment';
import { UserService } from 'src/app/users/user.service';
import { AssesmentService } from 'src/app/assesment/assesment.service';
import { NotificationsService } from 'src/app/notifications/notifications.service';

@Component({
  selector: 'sym-allocate-user',
  templateUrl: './allocate-user.component.html',
  styleUrls: ['./allocate-user.component.scss'],
})
export class AllocateUserComponent implements OnInit {
  users: any;
  application: any;
  allocatableUsers: any[];
  userFirstName: string;
  userLastName: string;
  userFullName: string;
  administratorId: string;
  analystId: string;
  technicalSignatoryId: string;
  userId: string;
  admins: Admin[] = [
    { value: 'admin', viewValue: 'Admin' },
  ];
  ts: Ts[] = [
    { value: 'ts', viewValue: 'Technical Signatory' },
  ];
  analysts: Analyst[] = [
    { value: 'analyst', viewValue: 'Analyst' },
  ];
  allocateForm: UntypedFormGroup;
  officeForm: UntypedFormGroup;
  private applicationData: Applications[];
  private counter = 0;
  private newApplication: Applications;
  private splitted: string[];
  private splitted2: string[];
  closeDialog = () => {
    this.dialogRef.close()
  }

  constructor(
    private fb: UntypedFormBuilder,
    public userService: UserService,
    private assesmentService: AssesmentService,
    public loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AllocateUserComponent>,
    public coms: CommunicationService,
    public lookupService: LookupService,
    public notificationService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getUserList();
    this.application = this.data;
    this.allocateForm = this.fb.group({
      tsForm: this.fb.group({
        tsAssignTo: ['', [Validators.required]],
        tsAssesementRole: ['Technical Signatory', [Validators.required]],
      }),
      analystForm: this.fb.group({
        analystAssignTo: ['', [Validators.required]],
        analystAssesementRole: ['Analyst', [Validators.required]]
      }),
      adminForm: this.fb.group({
        adminAssignTo: ['', [Validators.required]],
        adminAssesementRole: ['Admin', [Validators.required]]
      })
    });
    this.officeForm = this.fb.group({
      originatingOffice: ['', Validators.required]
    });
  }

  getUserList(){
    this.loadingService.openBlockingLoader('Retrieving users', 'primary', 'indeterminate', 0);
    this.userService.getUserList().subscribe((res: any) => {
      this.users = res;
      this.allocatableUsers = [];
      this.users.forEach(element => {
        this.userFirstName = element.firstname;
        this.userLastName = element.lastname;
        this.userFullName = this.userFirstName + ' ' + this.userLastName;
        this.userId = element.id;
        this.allocatableUsers.push(this.userFullName);
      });
      this.loadingService.closeBlockingLoader();
    }, error => {
      this.coms.showMsg('There was an error retrieving the users', 'error');
      this.loadingService.closeBlockingLoader();
    });
  }

  getAllocationList() {
    this.users = JSON.parse(localStorage.getItem('assignees'));
    this.allocatableUsers = [];
    this.users.forEach(element => {
      this.userFirstName = element.firstname;
      this.userLastName = element.lastname;
      this.userId = element.id;
      this.userFullName = this.userFirstName + ' ' + this.userLastName;
      this.allocatableUsers.push(this.userFullName);
    });
  }

  save() {
    this.loadingService.openBlockingLoader('Updating Verification Team', 'primary', 'indeterminate', 0);
    this.assesmentService.getApplicationDetails(this.application.application).subscribe((res: any) => {
      for(let i =0 ; i < this.users.length; i++) {
        if(this.allocateForm.value.adminForm.adminAssignTo == this.users[i].firstname + ' ' + this.users[i].lastname) {
          this.administratorId = this.users[i].id;
        }
        if(this.allocateForm.value.analystForm.analystAssignTo == this.users[i].firstname + ' ' + this.users[i].lastname) {
          this.analystId = this.users[i].id;
        }
        if(this.allocateForm.value.tsForm.tsAssignTo == this.users[i].firstname + ' ' + this.users[i].lastname) {
          this.technicalSignatoryId = this.users[i].id;
        }
      }

      const verificationTeam = {
          administrator: this.allocateForm.value.adminForm.adminAssignTo,
          administratorId: this.administratorId,
          analyst: this.allocateForm.value.analystForm.analystAssignTo,
          analystId: this.analystId,
          technicalSignatory:this.allocateForm.value.tsForm.tsAssignTo,
          technicalSignatoryId: this.technicalSignatoryId
      }
      const assesmentDetails =  res.assessment.application;
      const assessmentId = res.assessment.id;
      const assesment = {
        assessment: {
          id: res.assessment.id,
          application: assesmentDetails,
          verificationTeam  : verificationTeam,
          originatingOffice :  this.officeForm.value.originatingOffice,
          assessmentStatus: 'In Progress'
        }
      };
        this.assesmentService.updateAssesment(assesment,assessmentId).subscribe((res: any) => {
          this.loadingService.closeBlockingLoader();
          this.dialogRef.close();
          this.coms.showMsg('Verification Team Assigned', 'success');
          this.notificationService.pingNotifications(true);
        // this.notificationService.refreshScreen(true);
      }, error => {
        console.log(error);
        this.coms.showMsg('There was an error updating the verification team', 'error');
        this.loadingService.closeBlockingLoader();
      });
    }, error => {
      console.log(error);
      this.coms.showMsg('There was an error retrieving the application.', 'error');
      this.loadingService.closeBlockingLoader();
    });
  }
}

interface Admin {
  value: string;
  viewValue: string;
}
interface Ts {
  value: string;
  viewValue: string;
}
interface Analyst {
  value: string;
  viewValue: string;
}
