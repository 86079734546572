import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../../app/users/user.service';
import { LookupService } from '../core/services/lookup/lookup.service';
import { CommunicationService } from '../components/communication/communication.service';
import { LoadingService } from '../components/loading/loading.service';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from './dashboard.service';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { AssesmentService } from '../assesment/assesment.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '../notifications/notifications.service';
import { AllocateUserComponent } from '../components/allocate-user/allocate-user.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'tlvt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('allPaginator') allAppPaginator: MatPaginator;
  @ViewChild('myPaginator') myPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  assesmentsList: any;
  resultsLength = 0;
  tabIndex = 0;
  pageIndex = 0;
  currentUser: any;
  sortedData: any[];
  statusType = ['New',
  'In Progress',
  'Rejected',
  'Client approval',
  'Updated',
  'Temporarily Rejected',
  'Quote approved']
  public displayedColumns: string[] = [
    'dateReceived',
    'assessmentStatus',
    'companyName',
    // 'sector',
    'service',
    'originatingOffice',
    'assignedAdmin',
    'assignedTechnicalSignatory',
    'assignedAnalyst',
    'edit',
  ];
  searchForm: UntypedFormGroup;
  @Output() hasSearched: EventEmitter<any> = new EventEmitter();
  servicesList: any;
  applicationStatusList: any;
  applicationStatusValuesList: any
  servicesValuesList: any;
  myArray: any;
  names: any;
  userNames: any;
  availableAssignees: any;
  allApplications = false;
  roles: any;
  isSuperUser = false;
  isAdminUser = false;
  userLevel = 0;
  constructor(
    public lookupService: LookupService,
    public coms: CommunicationService,
    public router: Router,
    private assesmentService: AssesmentService,
    public loadingservice: LoadingService,
    private fb: UntypedFormBuilder,
    public dashboardService: DashboardService,
    public userService: UserService,
    private notificationService: NotificationsService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.notificationService.refresh.subscribe((data: any) => {
      if(data){
        if (this.tabIndex === 0) {
          this.getNewApplications(this.pageIndex);
        } else if (this.tabIndex === 1) {
          this.getMyApplications(this.pageIndex);
        } else if (this.tabIndex === 2) {
          this.getAllApplications(this.pageIndex);
        }
      }
     })
     
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.roles = this.currentUser.roles;
    if(this.roles) {
      this.roles.forEach(element => {
        if (element.includes('Super User')) { 
          this.isSuperUser = true;
        }
      });
      this.roles.forEach(element => {
        if (element.includes('Admin')) { 
          this.isAdminUser = true;
        }
      });
    }

    if(this.isSuperUser || this.isAdminUser){
      this.getNewApplications(this.pageIndex);
    }else {
      this.getMyApplications(this.pageIndex);
    }

    this.searchForm = this.fb.group({
      searchString: [],
      applicationStatus: [],
      service: [],
    });
    this.getApplicationLookup();
    this.clearSearch();
  }

  viewApplicationClick(name, id) {
    this.router.navigate(['view-assesment/', { company: name, cid: id }]);
  }

  manageApplicationClick(name, id, applicationId) {
    const user = this.currentUser.email;
    var Obj = [
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Service Required', commentCategory: 'General Information' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Marketing', commentCategory: 'General Information' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Impartiality', commentCategory: 'General Information' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'BBBEE-Comission', commentCategory: 'General Information' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Additional Details', commentCategory: 'General Information' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Measured Entity', commentCategory: 'Client Details' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Physical Address', commentCategory: 'Client Details' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Postal Address', commentCategory: 'Client Details' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Additional Offices', commentCategory: 'Client Details' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Financial', commentCategory: 'Client Details' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Contact', commentCategory: 'Client Details' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Primary Contact', commentCategory: 'Client Details' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Ownership', commentCategory: 'Elements For Verification' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Management', commentCategory: 'Elements For Verification' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Skills development', commentCategory: 'Elements For Verification' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Other', commentCategory: 'Elements For Verification' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Enterprise and Supplier Development', commentCategory: 'Elements For Verification' },
      { assessmentId: id, applicationId: applicationId, commentBy: user, commentText: '', companyName: name, cardName: 'Socio Economic', commentCategory: 'Elements For Verification' }];

    localStorage.setItem('AssesmentComments', JSON.stringify(Obj));
    this.router.navigate(['assesment/', { company: name, cid: id }]);
  }

  changeTab(event) {
    this.tabIndex = event.index;
    this.pageIndex = 0;
    if(this.isAdminUser || this.isSuperUser){
      if (this.tabIndex === 0) {
        this.getNewApplications(this.pageIndex);
      } else if (this.tabIndex === 1) {
        this.getMyApplications(this.pageIndex);
      } else if (this.tabIndex === 2) {
        this.getAllApplications(this.pageIndex);
      }
    }else {
      if (this.tabIndex === 0) {
        this.getMyApplications(this.pageIndex);
      } else if (this.tabIndex === 1) {
        this.getAllApplications(this.pageIndex);
      }
    }
  }

  getPaginatorData(event) {
    this.pageIndex = event.pageIndex;
    if (this.tabIndex === 0) {
      this.getNewApplications(this.pageIndex);
    } else if (this.tabIndex === 1) {
      this.getMyApplications(this.pageIndex);
    } else if (this.tabIndex === 2) {
      this.getAllApplications(this.pageIndex);
    }
  }

  getMyApplications(index) {
    this.loadingservice.openBlockingLoader('Loading My Applications', 'primary', 'indeterminate', 0);
    const userName = this.currentUser.firstname + " " + this.currentUser.lastname;
    this.assesmentService.getMyAssesments(userName, index).subscribe((res: any) => {
      this.allApplications = false;
      this.assesmentsList = res.assessmentDetailsList;
      this.sortedData = this.assesmentsList.slice();
      this.dataSource = new MatTableDataSource(this.assesmentsList);
      this.dataSource.paginator = this.myPaginator;
      this.resultsLength = res.totalNumberOfRecords;
      this.loadingservice.closeBlockingLoader();
    }, error => {
      this.coms.showMsg('There was an error retrieving your applications.', 'error');
      this.loadingservice.closeBlockingLoader();
    });
  }

  getNewApplications(index) {
    this.loadingservice.openBlockingLoader('Loading New Applications', 'primary', 'indeterminate', 0);
    this.assesmentService.getNewAssesments(index).subscribe((res: any) => {
      this.allApplications = false;
      this.assesmentsList = res.assessmentDetailsList;
      this.sortedData = this.assesmentsList.slice();
      this.dataSource = new MatTableDataSource(this.assesmentsList);
      this.dataSource.paginator = this.paginator;
      this.resultsLength = res.totalNumberOfRecords;
      this.loadingservice.closeBlockingLoader();
    }, error => {
      this.coms.showMsg('There was an error retrieving new applications.', 'error');
      this.loadingservice.closeBlockingLoader();
    });
  }

  allocateTeam(e) {
    const dialogRef = this.dialog.open(AllocateUserComponent, {
      panelClass: 'custom-user-dialog-container',
      height: '70%',
      width: '60%',
      data: {application: e}
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.tabIndex === 0) {
        this.getNewApplications(this.pageIndex);
      } else if (this.tabIndex === 1) {
        this.getMyApplications(this.pageIndex);
      } else if (this.tabIndex === 2) {
        this.getAllApplications(this.pageIndex);
      }
    })
  }

  getAllApplications(index) {
    this.loadingservice.openBlockingLoader('Loading All Applications', 'primary', 'indeterminate', 0);
    this.assesmentService.getAllAssesments(index).subscribe((res: any) => {
      this.allApplications = true;
      this.assesmentsList = res.assessmentDetailsList;
      this.sortedData = this.assesmentsList.slice();
      this.dataSource = new MatTableDataSource(this.assesmentsList);
      this.dataSource.paginator = this.allAppPaginator;
      this.resultsLength = res.totalNumberOfRecords;
      this.loadingservice.closeBlockingLoader();
    }, error => {
      this.coms.showMsg('There was an error retrieving all applications.', 'error');
      this.loadingservice.closeBlockingLoader();
    });
  }
  setSource(source) {
    this.dataSource = new MatTableDataSource(source);
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  sortData(sort: Sort) {
    const data = this.assesmentsList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dateReceived': return compare(a.dateReceived, b.dateReceived, isAsc);
        case 'companyName': return compare(a.companyName, b.companyName, isAsc);
        case 'sector': return compare(a.sector, b.sector, isAsc);
        case 'assessmentStatus': return compare(a.assessmentStatus, b.assessmentStatus, isAsc);
        case 'service': return compare(a.service, b.service, isAsc);
        case 'originatingOffice': return compare(a.originatingOffice, b.originatingOffice, isAsc);
        default: return 0;
      }
    });
    this.setSource(this.sortedData);
  } 
  search() {
    this.loadingservice.showLoaderBar('primary', 'query');
    localStorage.setItem('search', JSON.stringify(this.searchForm.value));
    this.hasSearched.emit(this.searchForm.value);
    this.dashboardService.getSearchedAssessments(this.searchForm.value).subscribe((res: any) => {
      if (res) {
        this.setSource(res.assessmentDetailsList);
        this.resultsLength = res.totalNumberOfRecords;
        this.loadingservice.stopLoadingBar();
      } else {
        this.coms.showMsg('The search criteria did not retrieve any applications, please try a different search', 'warning');
      }
    }, error => {
      this.loadingservice.stopLoadingBar();
    })
  }

  clearSearch() {
    const cleanSearch = {
      searchString: '',
      service: '',
      applicationStatus: '',
    };
    this.searchForm.setValue(cleanSearch);

  }

  clearCriteria() {
    const cleanSearch = {
      searchString: '',
      service: '',
      applicationStatus: '',
    };
    this.searchForm.setValue(cleanSearch);
    if (this.tabIndex === 0) {
      this.getNewApplications(this.pageIndex);
    } else if (this.tabIndex === 1) {
      this.getMyApplications(this.pageIndex);
    } else if (this.tabIndex === 2) {
      this.getAllApplications(this.pageIndex);
    }
  }

  getApplicationLookup() {
    this.lookupService.getApplicationLookUp().subscribe((res: any) => {
      const response = res.lookupList;
      for (let i = 0; i < response.length; i++) {
        let code = response[i].code;
        let values = response[i];
        if (code && code !== undefined) {
          if (code.includes('application') || code.includes('Application')) {
            this.applicationStatusList = values;
            this.setApplicationStatusValuesList();
            localStorage.setItem('app-status', JSON.stringify(this.applicationStatusValuesList));
          } else if (code.includes('services') || code.includes('Services')) {
            this.servicesList = values;
            this.setServicesValuesList();
            localStorage.setItem('service-required', JSON.stringify(this.servicesValuesList));
          }
        }
      }
    }, error => {
      this.coms.showMsg('There was an error retrieving the lookup data', 'error');
    });
  }

  setApplicationStatusValuesList() {
    if (this.applicationStatusList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.applicationStatusList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.applicationStatusValuesList = this.applicationStatusList.values;
        }
      }
    } else {
      this.applicationStatusValuesList = [];
    }
  }

  setServicesValuesList() {
    if (this.servicesList.values.length > 0) {
      let counter = 0;
      for (let i = 0; i < this.servicesList.values.length; i++) {
        counter++;
        if (counter === 1) {
          this.servicesValuesList = this.servicesList.values;
        }
      }
    } else {
      this.servicesValuesList = [];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}