import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportsComponent } from './imports.component';
import { BannerModule } from '../components/banner/banner.module';
import { TlvtCardModule } from '../components/tlvt-card/tlvt-card.module';
import { MaterialModule } from '../core/material/material.module';
import { BaseModule } from '../core/base/base.module';
import { InfoCardModule } from '../components/info-card/info-card.module';
import { FileUploadModule } from 'ng2-file-upload';

const routes: Routes = [
  {
    path: '',
    component: ImportsComponent
  },
];


@NgModule({
  declarations: [ImportsComponent],
  imports: [
    RouterModule.forChild(routes),
    BaseModule,
    MaterialModule,
    TlvtCardModule,
    BannerModule,
    InfoCardModule,
    FileUploadModule]
})
export class ImportsModule { }
