<div fxLayout="row" fxLayoutAlign="space-around center">
    <mat-progress-spinner 
    diameter="20" 
    [color]="color" 
    [mode]="mode" 
    [value]="value">
    </mat-progress-spinner>
    <div class="message-text p-4">
      {{message}}
    </div>
  </div>