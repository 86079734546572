<div style="width: 100%;background-color: #FFFFFF;height: 145px;box-shadow: 2px 0px 22px rgba(0, 0, 0, 0.05);">
    <div style="padding-top: 39px; padding-left:48px;width: 96%;"fxLayout="column">
        <sym-breadcrumb [breadcrumbText]="breadcrumbText" [routerNav]="routerNav" style="margin-bottom: 8px;"></sym-breadcrumb>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <span style="font-size: 34px;color: black;">{{title}}</span>
            <div fxLayout="row" fxLayoutAlign="end end">
                <div *ngIf="!primaryBtn && !menuBtn" style="height: 36px;"></div>
                <sym-primary-button [primaryBtn]="primaryBtn"></sym-primary-button>
                <button *ngIf="menuBtn" mat-button [matMenuTriggerFor]="buttonMenu" style="background-color: white;color: #383A41;border-radius: 100px;border: 1px solid #E7E7E7;margin-left:8px">{{menuBtn.text}}<mat-icon>
                    keyboard_arrow_down
                  </mat-icon></button>
                  <mat-menu  #buttonMenu="matMenu" yPosition="below" fxLayout="column" fxLayoutAlign="center center">
                    <div *ngIf="menuBtn">
                        <div *ngFor="let btn of menuBtn.methods">
                            <button mat-menu-item style="color: black" (click)="btn.method()"><mat-icon style="padding-right: 18px;color: black">{{btn.icon}}</mat-icon>{{btn.text}}</button>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>        
    </div>
</div>