import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  // Sidenav pages

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'assesment',
        loadChildren: () =>
          import('./assesment/assesment.module').then(
            (mod) => mod.AssesmentModule
          ),
          data: {
            breadcrumbStart: '/dashboard'
          }
      },
      {
        path: 'view-assesment',
        loadChildren: () =>
          import('./assesment/view-application/view-application.module').then(
            (mod) => mod.ViewApplicationModule
          ),
          data: {
            breadcrumbStart: '/dashboard'
          }
      },
      {
        path: 'client-detail',
        loadChildren: () =>
          import('./clients/client-detail/client-detail.module').then(
            (mod) => mod.ClientDetailModule
          ),
          data: {
            breadcrumbStart: '/clients'
          }
      },
      // {
      //   path: 'imports',
      //   loadChildren: () =>
      //     import('./imports/imports.module').then((m) => m.ImportsModule),
      // },
      {
        path: 'clients',
        loadChildren: () =>
          import('./clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'lookup-data',
        loadChildren: () =>
          import('./lookup-data/lookup-data.module').then(
            (m) => m.LookupDataModule
          ),
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'user-settings',
        loadChildren: () =>
          import('./user-settings/user-settings.module').then(
            (mod) => mod.UserSettingsModule
          )
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./notifications/notifications.module').then(
            (mod) => mod.NotificationsModule
          )
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
