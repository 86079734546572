import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { FlexModule } from '@angular/flex-layout';
import { SearchbarModule } from '../searchbar/searchbar.module';
import { BaseModule } from 'src/app/core/base/base.module';


@NgModule({
  declarations: [BannerComponent],
  imports: [
    BaseModule,
    SearchbarModule
  ],
  exports: [BannerComponent]
})
export class BannerModule { }
