<div class="header sticky-header" [ngStyle]="{'background-color': delete ? '#0D2430' : ''}" fxLayout="row" fxLayoutAlign="space-between center">
    <span style="font-size: 24px;">{{title}}</span>
    <div>
      <button mat-icon-button (click)="closeMethod()" matTooltip="Close dialog">
        <mat-icon style="color: white;">
          close
        </mat-icon>
      </button>
    </div>
  </div>
  <div style="padding: 20px 32px 32px 32px;">
    <ng-content></ng-content>
</div>