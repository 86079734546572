import { Component } from '@angular/core';
import { LookupService } from './core/services/lookup/lookup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tlvt-admin';
  constructor(
    public ls: LookupService
  ){}
  
}
