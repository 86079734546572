import {Component, OnInit, Input, HostListener, Output, EventEmitter, ElementRef, Directive, ViewChild} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

/**
 * Custom card component
 */
@Component({
  selector: 'sym-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})

export class InfoCardComponent implements OnInit {
  /** Title input */
  @Input() title: string;
  /** Showtooltip on button input */
  //@Input() tooltip: string;
  /** Showbutton boolean variable */
  @Input() showButton: true;
  /** Can hover effect boolean variable */
  @Input() canHover: boolean;
  /** Icon input for button */
  @Input() icon: string;
  /** Button click event emitter */
  @Output() buttonClicked = new EventEmitter<any>();
  /** Hovered effect listener */
  hovered: any;

  /** The constructor */
  constructor() { }

  /** Initialization */
  ngOnInit() {
    this.hovered = '#13b9a3';
  }
  /** On button clicked event */
  onButtonClicked() {
    this.buttonClicked.emit();
  }

  /** On hover effect event */
  onHoverEffect() {
    if (this.canHover) {
      this.hovered = '#2A3376';
    }
  }

  /** Remover hover effect event */
  removeHover() {
    if (this.canHover) {
      this.hovered = '#13b9a3';
    }
  }

}
