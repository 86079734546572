import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/users/user.service';

@Component({
  selector: 'sym-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  endPoint = environment.API.userManagement;
  imageSrc = '../assets/logos/tlvtfull.png';
  user: any;
  userName: any;
  userId: any;
  constructor() {
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem('loggedIn','yes');
  }

  displayErrorState(status: string) {
    console.log(status + '  ERROR');
  }
  displayErrorMessage(status: string) {
    console.log(status + '  ERROR');
  }
}
