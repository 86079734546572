
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpClient } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/users/user.service';
import { finalize, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { getMatIconFailedToSanitizeUrlError } from '@angular/material/icon';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    token = '';
    accessToken = '';
    refreshToken = '';
    count = 0;
    isRefreshing = false;
    jwtHelperService = new JwtHelperService();
    expTime: any;
    isLoggedIn = false;
    exp: any;
    d1 = new Date();
    currentUser: any;
    digitalSignature = false;

    constructor(public router: Router,
        public userService: UserService,
        public http: HttpClient,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.getUser();
        const user = this.currentUser;
        this.count++;
        if (user && user.isLoggedIn) {
            this.isLoggedIn = user.isLoggedIn;
            this.token = user.token;
            this.accessToken = user.accessToken;
            this.refreshToken = user.refreshToken;
        }
        if(request.url.includes('refresh') || request.url.includes('refresh_token')){
            return next.handle(request);
        }else {
            this.isRefreshing = false;
            if (this.checkRequestWhitelist(request.url)) {
                if(this.digitalSignature  && request.method === 'PUT'){
                    request = request.clone({
                        setHeaders: {
                            'Content-Type': 'application/octet-stream',
                        }
                    });
                    this.digitalSignature = false;
                }else if (this.digitalSignature && request.method === 'GET') {
                    request = request.clone({
                        setHeaders: {
                            
                        }
                    });
                    this.digitalSignature = false;
                }
                return next.handle(request);
            } else if (!this.isRefreshing) {
                if (this.isLoggedIn && this.tokenValid(this.token, request)) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: this.token,
                        }
                    });
                    this.digitalSignature = false;
                }
                return next.handle(request).pipe(tap(
                    event => {
                    },
                    error => {
                    }
                ), finalize(() => {
                    this.count--;
                }));
            } else {
                this.setUrlsForLater(request);
            }
        }
    }

    checkRequestWhitelist(url) {
        if (
            url.includes('login') ||
            url.includes('password') ||
            url.includes('refreshtoken') ||
            url.includes('auth') ||
            url.includes('bucket') || url.includes('s3')
        ) {
            if(url.includes('bucket') || url.includes('s3')){
                this.digitalSignature = true;
            }
            return true;
      } else {
            return false;
        }
    }
    tokenValid(token, req) {
        if (!token) {
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigateByUrl('login');
            location.reload();
            return false;
        }
        if (this.isTokenCloseToExpire(token)  || this.didTokenExpire(token)) {
            this.setUrlsForLater(req);
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                return this.userService.refreshToken(this.refreshToken).subscribe((data: any) => {
                    this.currentUser.token = data.token;
                    this.currentUser.accessToken = data.accessToken;
                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                    this.token = this.currentUser.token;
                    location.reload();
                    this.isRefreshing = false;
                    this.reRunServices();
                    return true;
                }, error => {
                    this.router.navigateByUrl('login')
                    this.isRefreshing = false;
                    return false;
                });
            }
        } else {
            return true;
        }
    }
    setUrlsForLater(req) {
        let requestedURLS = [];
        if (localStorage.getItem('requestedURLS')) {
            requestedURLS = JSON.parse(localStorage.getItem('requestedURLS'));
        }
        requestedURLS.push({
            url: req.url,
            method: req.method,
            body: req.body
        });
        this.saverequestedUrls(requestedURLS);
    }
    saverequestedUrls(urls) {
        localStorage.setItem('requestedURLS', JSON.stringify(urls));
    }
    reRunServices() {
        const requestedURLS = JSON.parse(localStorage.getItem('requestedURLS'));
        requestedURLS.forEach((url, i) => {
            if (url.method === 'GET') {
                this.http.get(url.url);
            }
            if (url.method === 'DELETE') {
                this.http.delete(url.url);
            }
            if (url.method === 'POST') {
                this.http.post(url.url, url.body);
            }
            if (url.method === 'PUT') {
                this.http.put(url.url, url.body);
            }
            requestedURLS.splice(i, 1);
            this.saverequestedUrls(requestedURLS);
        });
    }
    didTokenExpire(token) {
        const date = new Date().valueOf();
        const tokenDate = this.getTokenExpirationDate(token).valueOf();
        if (date > tokenDate) {
            return true;
        } else {
            return false;
        }
    }
    getTokenExpirationDate(token: string): Date {
        this.expTime = this.jwtHelperService.decodeToken(this.currentUser.token).exp;
        this.d1 = new Date(0);

        if (this.expTime === undefined) { return null; }
        const date = new Date(0);
        date.setUTCSeconds(this.expTime);
        return date;
    }
    isTokenCloseToExpire(token) {
        if (!token) { return true; }
        const tokenDate = this.getTokenExpirationDate(token);
        const tokenExp = tokenDate.valueOf();
        const date = new Date().valueOf();
        if (tokenExp === undefined) { return false; }
        const timeDiff = tokenExp - date;
        if (timeDiff <= 300000) {
            return true;
        } else if (tokenExp < date) {
            return true;
        } else {
            return false;
        }
    }

    getUser() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
}
