import { NgModule } from '@angular/core';
import { TlvtCardComponent } from './tlvt-card.component';
import { BaseModule } from 'src/app/core/base/base.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [TlvtCardComponent],
  imports: [
    BaseModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    TlvtCardComponent
  ]
})
export class TlvtCardModule { }
