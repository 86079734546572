export class Navigation {
    public navItems: any[];

    constructor() {
        this.navItems = [
            { name: 'Dashboard', link: '/dashboard', icon: 'dashboard' },
            { name: 'Clients', link: '/clients', icon: 'person' },
            { name: 'Users', link: '/users', icon: 'face' },
            { name: 'Reports', link: '/reports', icon: 'source' },
            { name: 'Lookup Data', link: '/lookup-data', icon: 'list_alt' },
            // { name: 'Sign out', link: '/login', icon: 'logout' }
        ];
    }
}
