import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { HeaderDirective } from './header.directive';
import { MatFormFieldModule,} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule} from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import {FormsModule} from '@angular/forms';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { ProfileSummaryModule } from 'src/app/components/profile-summary/profile-summary.module';

@NgModule({
  declarations: [HeaderComponent, HeaderDirective],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatTooltipModule,
    LoadingModule,
    ProfileSummaryModule
  ],
  exports: [
    HeaderComponent, HeaderDirective
  ]
})
export class HeaderModule { }
