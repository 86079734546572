import { NgModule } from '@angular/core';
import { ProfileSummaryComponent } from './profile-summary.component';
import { BaseModule } from 'src/app/core/base/base.module';
import { MaterialModule } from 'src/app/core/material/material.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [ProfileSummaryComponent],
  imports: [
    BaseModule,
    MaterialModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule
  ],
  exports: [
    ProfileSummaryComponent
  ]
})
export class ProfileSummaryModule { }
