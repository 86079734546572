import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    assesmentApi = environment.API.assesmentManagement;
    searchString : any;
    constructor(private http: HttpClient) {
    }

    getAllAssesments() {
        return this.http.get(this.assesmentApi + 'all');
    }

    getSearchedAssessments(search){
        const queryString = this.assesmentApi +'/filter' + '?servicesRequired=' + search.service + '&assessmentStatus=' + search.applicationStatus  + '&company=' + search.searchString;
        return this.http.get(queryString);
    }
}

