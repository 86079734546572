import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AssesmentService } from 'src/app/assesment/assesment.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { UserService } from 'src/app/users/user.service';
import { LookupService } from '../../core/services/lookup/lookup.service';
import { CommunicationService } from '../communication/communication.service';
import { LoadingService } from '../loading/loading.service';
@Component({
  selector: 'sym-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
 
  constructor(
  ) {
  }

  ngOnInit() {
  
  }







  





}
