import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  usersApi = environment.API.userManagement;
  mobileUsersApi = environment.API.userManagement;
  item: any;
  public userList: any;

  constructor(private http: HttpClient) {
    this.setUserList();
  }

  getUserList() {
    return this.http.get(this.usersApi);
  }

  createNewUser(user) {
    return this.http.post(this.usersApi, user);
  }

  updateUser(user, id) {
    return this.http.put(this.usersApi + '/' + id, user);
  }

  deleteExistingUser(id) {
    return this.http.delete(this.usersApi + '/' + id);
  }

  getUserDetails(id) {
    return this.http.get(this.usersApi + '/' + id);
  }

  refreshToken(refreshTokens) {
    this.item = {
      refreshToken: refreshTokens,
    }
    return this.http.post(this.usersApi + '/auth/refreshtoken', this.item);
  }

  setUserList() {
    //this.userList = USERS;
  }

}
