import { Component, OnInit } from '@angular/core';
/**
 * Parent component for auth components
 */
@Component({
  selector: 'sym-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  /**
   * Auth class constructor
   */
  constructor() { }
/**
 * Init for auth class
 */
  ngOnInit() {
  }

}
