import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
/**
 * Authguard component for checking route authentication
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  /**
   * The constructor
   * @param router Router service for navigation
   * @param authService Authentication service for http requests
   */
  constructor(private readonly router: Router, private readonly authService: AuthService) { }

  /**
   * Determines whether the next route can be activated
   * @param next The next route in the queue
   * @param state The current state of the router
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let url: string = state.url;

    return this.checkLogin(url);
  }

  /**
   * Determines whether a child route can be activated
   * @param route The child route
   * @param state The current state of the router
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

  /**
   * Determines logged in state of user
   * @param url The next url in the queue
   */
  checkLogin(url: string): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (sessionStorage.getItem('loggedIn') != null) {
      const isLogged = currentUser && currentUser.isLoggedIn ? true : false;
      if (isLogged) { 
        return true; 
      } else {
        this.goToLogin(url);
      }
    } else {
      return this.goToLogin(url);
    }
  }

  goToLogin(url: string): boolean {
    this.authService.redirectUrl = url;
    this.router.navigate(['/login']);
    localStorage.clear();
    sessionStorage.clear();
    return false;
  }
}
