import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LookupService } from '../core/services/lookup/lookup.service';

@Component({
  selector: 'sym-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit {
  // importForm: FormGroup;
  importType: any;
  constructor(
    // public lookupService: LookupService,
    // private fb: FormBuilder,
  ) { }

  ngOnInit() {
    // if (!this.lookupService.applicationLookupData && !this.lookupService.applicationLookupData.serviceRequired) {
    //   this.lookupService.getApplicationLookUpData();
    // }

    // this.importForm = this.fb.group({
    //   serviceRequired: ['', Validators.required]
    // });
  }

  // uploadBatchFile() {

  // }

}
