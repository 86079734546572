<div fxLayout="row" *ngIf="!navItem && breadCrumbs.length > 1">
    <div *ngFor="let crumb of breadCrumbs; let i = index">
        <span *ngIf="i === 0" (click)="goToUrl(crumb)" [ngStyle]=" breadCrumbs.length - 1 === i ? {'color': '#65BBD6'} : {'color': '#65BBD6', 'cursor': 'pointer'}">{{checkNavName(crumb) + ' / '}}</span>
        <span *ngIf="i > 0 && i != breadCrumbs.length - 1" (click)="goToUrl(crumb)" [ngStyle]=" breadCrumbs.length - 1 === i ? {'color': '#65BBD6'} : {'color': '#65BBD6', 'cursor': 'pointer'}">{{"&nbsp;&nbsp;" + checkNavName(crumb) + ' / '}}</span>
    </div>
</div>
<div *ngIf="navItem || breadCrumbs.length === 1">
    <span style="visibility: hidden;">T</span>
</div>

