import { NgModule } from '@angular/core';
import { AllocateUserComponent } from './allocate-user.component';
import { MaterialModule } from 'src/app/core/material/material.module';
import { BaseModule } from 'src/app/core/base/base.module';
import { CardDialogModule } from 'src/app/shared/components/card-dialog/card-dialog.module';



@NgModule({
    declarations: [AllocateUserComponent],
    imports: [
        BaseModule,
        MaterialModule,
        CardDialogModule
    ],
    exports: [
        AllocateUserComponent
    ]
})
export class AllocateUserModule { }
