import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    assessmentApi = environment.API.assesmentManagement;
    @Output() getNotification = new EventEmitter<boolean>();
    @Output() refresh = new EventEmitter<boolean>();

    constructor(private http: HttpClient) {
    }

    getNotifications(userId){
        return this.http.get(this.assessmentApi + '/notifications/by/userid/' + userId);
    }

    updateNotifications(notification) {
        return this.http.put(this.assessmentApi + '/notifications/updateAssessmentsNotification',notification);
    }

    pingNotifications(data: any) {
        this.getNotification.emit(data);
     }

     refreshScreen(data: any) {
        this.refresh.emit(data);
     }
}
