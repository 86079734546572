// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`

const REGION_PRIMARY = 'eu-west-1';

const BUCKET_PRIMARY = 'AppBucketName';
const BUCKET_OTHER = 'SecondBucketName'; // Change variable and value as needed
const DIGITAL_SIGNATURE_BASE_PATH = 'https://api.dev.tlvt.co.za/clientdocument/v1/documentservices/digitalsignature';
const DOCUMENT_BASE_PATH = ' https://api.dev.tlvt.co.za/clientdocument/v1/documentservices';
const ASSESMENT_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/assessment/v1/assessments';
const RISK_ASSESSMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/assessment/v1/assessments/risk/'
const USER_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/usermanagement/v1';
const LOOKUP_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/lookupservice/v1/lookupservice/';
const CLIENT_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/clientmanagement/v1/clientservice';
const PRICING_PACK_MANAGEMENT_BASE_PATH = 'https://api.dev.tlvt.co.za/pricingpack/v1/pricingpackservice';
const COR_04_BASE_PATH = 'https://api.dev.tlvt.co.za/cor04management/v1/cor04service/byassessmentid/'; 
const COMMENTS_BASE_PATH = 'https://api.dev.tlvt.co.za/commentservice/v1/commentservice'

export const environment = {
  production: false,

  bucketName: BUCKET_PRIMARY,
  filesBucket: '',
  bucketRegion: REGION_PRIMARY,

  Storage: {
    bucket: BUCKET_PRIMARY,
    region: REGION_PRIMARY,
  },

  API: {
    userManagement: USER_MANAGEMENT_BASE_PATH,
    lookupManagement: LOOKUP_MANAGEMENT_BASE_PATH,
    clientManagement: CLIENT_MANAGEMENT_BASE_PATH,
    assesmentManagement: ASSESMENT_MANAGEMENT_BASE_PATH,
    pricingPackManagement: PRICING_PACK_MANAGEMENT_BASE_PATH,
    signatureManagement: DIGITAL_SIGNATURE_BASE_PATH,
    documentManagement: DOCUMENT_BASE_PATH,
    commentManagement: COMMENTS_BASE_PATH,
    riskManagement: RISK_ASSESSMENT_BASE_PATH,
    cor04: COR_04_BASE_PATH
  },
};