import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  lookupApi = environment.API.lookupManagement;

  applicationLookupData: any;
  userDeclarationLookupData: any;
  pricingPackLookupData: any;
  backOfficeLookupData: any;
  backOfficeAdditionalData: any;
  customerDetailPricingpack: any;
  customerDetailApplicationForm: any;
  customerDetailRiskAssesment: any;
  customerDetailOther: any;

  constructor(private http: HttpClient) {
    this.getLookupData();
  }

  getLookupData() {

    this.getApplicationLookUp();
    this.getApplicationLookUpData();
    this.getUserDeclarationLookUpData();
    this.getPricingPackLookupData();
    this.getBackOfficeLookupData();
    this.getBackOfficeAdditionalLookupData();
    this.getCustomerDetailPricingPack();
    this.getCustomerDetailApplicationForm();
    this.getCustomerDetailRiskAssesment();
    this.getCustomerDetailOther();
  }
  getApplicationLookUp() {
    return this.http.get(this.lookupApi + 'lookup');
  }
  updateLookupInformation(body) {
    return this.http.post(this.lookupApi + 'lookup', body);
  }
  getPricingPackLookupData() {
    const pricingMock = {
      pricingPacks: [
        {
          id: 1,
          element: '',
          activity: ['Opening & Closing meeting'],
          totalPop: '',
          sampleSize: '',
          minMinutes: '',
          alloMinutes: '',
          docSugMinutes: 0,
          docAlloMinutes: 0,
        },
        {
          id: 2,
          element: 'O',
          activity: ['Number Black Natural Person Shareholders'],
          totalPop: 10,
          minMinutes: 15,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 3,
          element: 'MC',
          activity: ['Number of Directors'],
          totalPop: 4,
          minMinutes: 15,
          sampleSize: 2,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 4,
          element: 'MC',
          activity: ['Top Management'],
          totalPop: 5,
          minMinutes: 15,
          sampleSize: 1,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 5,
          element: 'EE',
          activity: ['TOTAL CAPTURING AND REVIEW TIME'],
          totalPop: '',
          minMinutes: '',
          sampleSize: '',
          alloMinutes: '',
          docSugMinutes: '',
          docAlloMinutes: '',
        },
        {
          id: 6,
          element: 'EE',
          activity: ['Senior Management'],
          totalPop: 5,
          minMinutes: 15,
          sampleSize: 1,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 7,
          element: 'EE',
          activity: ['Middle Management'],
          totalPop: 5,
          minMinutes: 15,
          sampleSize: 1,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 8,
          element: 'EE',
          activity: ['Junior Management'],
          totalPop: 2,
          minMinutes: 15,
          sampleSize: 1,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 9,
          element: 'EE',
          activity: ['Disabled Employees'],
          totalPop: 1,
          minMinutes: 15,
          sampleSize: 2,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 10,
          element: 'SD',
          activity: ['Number of black persons on training'],
          totalPop: 243,
          minMinutes: 15,
          sampleSize: 25,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 11,
          element: 'PP',
          activity: ['Procurement'],
          totalPop: 2,
          minMinutes: 15,
          sampleSize: 1,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 12,
          element: 'PP',
          activity: ['Imports'],
          totalPop: 2,
          minMinutes: 15,
          sampleSize: 1,
          alloMinutes: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 13,
          element: 'SUP DEV',
          activity: ['Supplier Development Contributions'],
          totalPop: 3,
          minMinutes: 15,
          alloMinutes: 2,
          sampleSize: 1,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 14,
          element: 'ED',
          activity: ['Enterprise Development Contributions'],
          totalPop: 2,
          minMinutes: 15,
          alloMinutes: 2,
          sampleSize: 2,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        },
        {
          id: 15,
          element: 'SED',
          activity: ['Socio Economic Development Contributions'],
          totalPop: 3,
          minMinutes: 15,
          alloMinutes: 2,
          docSugMinutes: 1,
          sampleSize: 2,
          docAlloMinutes: 0,
        },
        {
          id: 16,
          element: 'OTHER',
          activity: [''],
          totalPop: 4,
          minMinutes: 15,
          alloMinutes: 2,
          sampleSize: 3,
          docSugMinutes: 1,
          docAlloMinutes: 0,
        }
      ]
    };
    localStorage.setItem('pricingPackLookup', JSON.stringify(pricingMock));
    this.pricingPackLookupData = pricingMock;
  }

  getBackOfficeLookupData() {
    const backOfficeMok = {
      backOffices: [
        {
          id: 1,
          activity: ['Risk Assessment & Engagement'],
          description: [{
            name: 'Review Engagement Risks'
          },
          {
            name: 'Allocate verification Team'
          }, {
            name: 'Prepare and follow-up Engagement Letter'
          }
          ],
          allocatedHours: 0,
          price: 0
        },
        {
          id: 2,
          activity: ['Introductory meeting'],
          description: [{
            name: 'Meeting after engagement but before any activities to prepare the client for the process, and make arrangements for completion of all activities'
          }],
          allocatedHours: 0,
          price: 0
        },
        {
          id: 3,
          activity: ['Information gathering'],
          description: [{
            name: 'Collect Information '
          }],
          allocatedHours: 0,
          price: 0
        },
        {
          id: 4,
          activity: ['Document review'],
          description: [{
            name: 'Review Documentation'
          }, {
            name: 'Capture preliminary scorecard for acceptance'
          }, {
            name: 'Prepare Audit Plan'
          }, {
            name: 'Select samples'
          }, {
            name: 'Send notice'
          }, {
            name: 'Verification activities on documents before site visit'
          }, {
            name: 'Finalise client file ready for review'
          }],
          allocatedHours: 0,
          price: 0
        },
        {
          id: 5,
          activity: ['Site visit - All Sites'],
          description: [{
            name: 'On Site Verification Activities per On-Site Calculation'
          }],
          allocatedHours: 0,
          price: 0
        },
        {
          id: 6,
          activity: ['Verification Review'],
          description: [{
            name: 'Review File'
          },
          {
            name: 'Resolve Queries'
          }, {
            name: 'Issue Certificate'
          }],
          allocatedHours: 0,
          price: 0
        },
        {
          id: 7,
          activity: ['Travel'],
          description: [{
            name: 'Time'
          }],
          allocatedHours: 0,
          price: 0
        }
      ]
    };
    localStorage.setItem('officeLookup', JSON.stringify(backOfficeMok));
    this.backOfficeLookupData = backOfficeMok;
  }

  getBackOfficeAdditionalLookupData() {
    const backOfficeAdditionalMok = {
      backOfficesAdditional: [
        {
          id: 1,
          description: 'Travel: [Min R300 for Gauteng and R500 for Cape Town]',
          value: 0
        },
        {
          id: 2,
          description: 'Disbursements: Courier Fee [Min R300]',
          value: 0
        },
        {
          id: 3,
          description: 'AUTHORIZED DISCOUNT',
          value: 0
        }
      ]
    };
    localStorage.setItem('officeAdditionalLookup', JSON.stringify(backOfficeAdditionalMok));
    this.backOfficeAdditionalData = backOfficeAdditionalMok;
  }

  getApplicationLookUpData() {
    const lookUpMock = {
      industry: [
        {
          id: '1',
          code: '',
          displayValue: 'Forestry',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '2',
          code: '',
          displayValue: 'Fishing',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '3',
          code: '',
          displayValue: 'Mining',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '4',
          code: '',
          displayValue: 'Manufacturing',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '5',
          code: '',
          displayValue: 'Construction',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '6',
          code: '',
          displayValue: 'Retail',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '7',
          code: '',
          displayValue: 'Tourism',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '8',
          code: '',
          displayValue: 'Hospitality',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '9',
          code: '',
          displayValue: 'Transport',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '10',
          code: '',
          displayValue: 'Finance',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '11',
          code: '',
          displayValue: 'Law',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '12',
          code: '',
          displayValue: 'Law',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '13',
          code: '',
          displayValue: 'Agriculture',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '14',
          code: '',
          displayValue: 'Defence',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '15',
          code: '',
          displayValue: 'Media',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        }
      ],
      applicationStatus: [
        {
          id: '1',
          code: '',
          displayValue: 'New',
          description: 'A new application received.',
          createDate: '',
          updateDate: '',
          enabled: Boolean
        },
        {
          id: '2',
          code: '',
          displayValue: 'In Progress',
          description: 'An application review and risk assessment in progress by TLVT user.',
          createDate: '',
          updateDate: '',
          enabled: Boolean
        },
        {
          id: '3',
          code: '',
          displayValue: 'Accepted',
          description: 'Risk assessment accepted.',
          createDate: '',
          updateDate: '',
          enabled: Boolean
        },
        {
          id: '4',
          code: '',
          displayValue: 'Rejected',
          description: 'Risk assessment rejected.',
          createDate: '',
          updateDate: '',
          enabled: Boolean
        },
        {
          id: '5',
          code: '',
          displayValue: 'Client Feedback',
          description: 'Quote and engagement letter submitted back to client for feedback.',
          createDate: '',
          updateDate: '',
          enabled: Boolean
        },
        {
          id: '6',
          code: '',
          displayValue: 'Assigned',
          description: 'Client feedback received and assigned to TLVT user, awaiting action.',
          createDate: '',
          updateDate: '',
          enabled: Boolean
        }

      ],
      serviceRequired: [
        {
          id: '1',
          code: '',
          displayValue: 'Initial BEE Verification',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '2',
          code: '',
          displayValue: 'Annual BEE Verification',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '3',
          code: '',
          displayValue: 'B-BBEE Consulting',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '4',
          code: '',
          displayValue: 'BEE Scenario Planning',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '5',
          code: '',
          displayValue: 'Scorecard Compilation',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '6',
          code: '',
          displayValue: 'Document Preparation',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '7',
          code: '',
          displayValue: 'Opinion',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '8',
          code: '',
          displayValue: 'Other',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        }
      ],
      marketing: [
        {
          id: '1',
          code: '',
          displayValue: 'Referal from TLVT Client',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '2',
          code: '',
          displayValue: 'SANAS website',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '3',
          code: '',
          displayValue: 'TLVT website',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '4',
          code: '',
          displayValue: 'Google',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        }, {
          id: '5',
          code: '',
          displayValue: 'Consultant',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '6',
          code: '',
          displayValue: 'Other',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        }
      ],
      branch: [
        'Cape Town',
        'Johannesburg'
      ],
      users: [
        'Unallocated',
        'Natalie Urwin',
        'Gerhard van Niekerk',
        'Jaco van der Merwe',
        'Ntula Thoso',
        'Christo Pieterse'
      ],
      conflictOfInterest: [
        {
          id: '1',
          code: '',
          displayValue: 'Shareholding',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '2',
          code: '',
          displayValue: 'Personnel',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        }
      ],
      assesementRole: [
        {
          id: '1',
          code: '',
          displayValue: 'Super User',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '2',
          code: '',
          displayValue: 'Techincal Signatory',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '3',
          code: '',
          displayValue: 'Analyst',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '4',
          code: '',
          displayValue: 'Administrator',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        }
      ]
    };
    localStorage.setItem('applicationLookup', JSON.stringify(lookUpMock));
    this.applicationLookupData = lookUpMock;
  }

  getUserDeclarationLookUpData() {
    const lookUpMock = {
      sectorCodes: [
        {
          id: '1',
          code: '',
          displayValue: 'COGP',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '2',
          code: '',
          displayValue: 'Agri-BEE',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '3',
          code: '',
          displayValue: '​Construction',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '4',
          code: '',
          displayValue: 'Financial',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '5',
          code: '',
          displayValue: '​Forestry',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '6',
          code: '',
          displayValue: 'Transport',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '7',
          code: '',
          displayValue: 'Construction',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '8',
          code: '',
          displayValue: 'Information and Communication Technology',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '9',
          code: '',
          displayValue: 'Marketing, Advertising and Communication',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '10',
          code: '',
          displayValue: '​Property',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '11',
          code: '',
          displayValue: 'Tourism',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: true
        },
        {
          id: '12',
          code: '',
          displayValue: 'Defence',
          description: '',
          createDate: '',
          updateDate: '',
          enabled: Boolean
        }]
      /** ToDo /  Option to Consider
,
 */
      // sectorCodes: [
      //   '​01-03: Agriculture, forestry and fishing',
      //   '05-09: ​Mining and quarrying',
      //   '​10-33: Manufacturing',
      //   '​35: Electricity, gas, steam and air conditioning supply',
      //   '​36-39: Water supply; sewerage, waste management and remediation activities',
      //   '41-43: Construction',
      //   '45-47: Wholesale and retail trade; repair of motor vehicles and motorcycles',
      //   '49-53: Transportation and storage',
      //   '55-56: Accommodation and food service activities',
      //   '​58-63: Information and communication',
      //   '64-66: Financial and insurance activities',
      //   '68: Real estate activities',
      //   '69-75: Professional, scientific and technical activities',
      //   '77-82: Administrative and support service activities',
      //   '84: Public administration and defence; compulsary social security',
      //   '​85: Education',
      //   '​​​86-88: Human health and social work activities',
      //   '90-93: Arts, entertainment and recreation',
      //   '94-96: Other service activities',
      //   '​97-98: Activities of households as employers; undifferentiated goods-and-service-producing activities of households for own use',
      //   '99: Activities of extraterritorial organisations and bodies, not economically active people, unemployed people etc.'
      // ]
    };
    localStorage.setItem('userDeclarationLookupData', JSON.stringify(lookUpMock));
    this.userDeclarationLookupData = lookUpMock;
  }

  getCustomerDetailPricingPack() {
    const pricingPackMock = {
      files: [{
        name: 'Pricing Pack',
        children: [
          {
            name: '2019',
            children: [
              { name: 'PricingPack.pdf' },
            ]
          }, {
            name: '2020',
            children: [
              { name: 'PricingPack.pdf' },
            ]
          },
        ]
      }
      ]
    };
    localStorage.setItem('customerDetailPricingPack', JSON.stringify(pricingPackMock));
    this.customerDetailPricingpack = pricingPackMock;
  }

  getCustomerDetailApplicationForm() {
    const ApplicationFormkMock = {
      files: [{
        name: 'Application Form',
        children: [
          {
            name: '2020',
            children: [
              { name: 'ApplicationForm.pdf' },
            ]
          },
        ]
      }
      ]
    };
    localStorage.setItem('customerDetailApplicationForm', JSON.stringify(ApplicationFormkMock));
    this.customerDetailApplicationForm = ApplicationFormkMock;
  }

  getCustomerDetailRiskAssesment() {
    const riskAssesmentkMock = {
      files: [{
        name: 'Risk Assesment',
        children: [
          {
            name: '2019',
            children: [
              { name: 'RiskAssesment.pdf' },
            ]
          }, {
            name: '2020',
            children: [
              { name: 'RiskAssesment.pdf' },
            ]
          },
        ]
      }
      ]
    };
    localStorage.setItem('customerDetailRiskAssesment', JSON.stringify(riskAssesmentkMock));
    this.customerDetailRiskAssesment = riskAssesmentkMock;
  }

  getCustomerDetailOther() {
    const otherkMock = {
      files: [{
        name: 'Other',
        children: [
          {
            name: '2019',
            children: [
              { name: 'Other.pdf' },
              { name: 'Other.pdf' },
              { name: 'Other.pdf' },
            ]
          }, {
            name: '2020',
            children: [
              { name: 'Other.pdf' },
              { name: 'Other.pdf' },
              { name: 'Other.pdf' },
            ]
          },
        ]
      }
      ]
    };
    localStorage.setItem('customerDetailOther', JSON.stringify(otherkMock));
    this.customerDetailOther = otherkMock;
  }

}
