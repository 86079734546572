<!-- <tlvt-profile></tlvt-profile> -->
<div [@onSideNavChange]="sideNavState ? 'open' : 'close'" fxLayoutAlign="center center">
    <!-- <profile></profile> -->

    <mat-nav-list >
        <a mat-list-item style="color: white;" [ngStyle]="returnHeight('margin-bottom', topVal)" fxLayoutAlign="center" (click)="openSideNav()"><mat-icon style="color: white;">{{sideNavState? 'first_page' : 'last_page'}}</mat-icon></a>
        <a mat-list-item *ngFor="let page of nav" [routerLink]="[page.link]" style="color: white;">
            <mat-icon class="pr-4" routerLinkActive="list-item-active">{{page?.icon}}</mat-icon>
            <span routerLinkActive="list-item-active" [@animateText]="sideNavState ? 'show' : 'hide'">{{ page?.name }} </span>
        </a>
        <div [ngStyle]="returnHeight('margin-top', bottomVal)">
            <button mat-fab *ngIf="!sideNavState" style="background-color: white;" (click)="goToLogin()" aria-label="Example icon button with a delete icon">
                <mat-icon style="color: black">logout</mat-icon>
              </button>
            <button mat-button *ngIf="sideNavState" style="background-color: white;border-radius: 100px;height: 56px;width: 266px;" (click)="goToLogin()" aria-label="Example icon button with a delete icon">
                <mat-icon style="margin-right: 16px;color: black;">logout</mat-icon> <span style="font-size: 16px;">Logout</span>
              </button>
        </div>
    </mat-nav-list>
</div>