declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BannerModule } from './components/banner/banner.module';
import { InfoCardModule } from './components/info-card/info-card.module';
import { MaterialModule } from './core/material/material.module';
import { BaseModule } from './core/base/base.module';
import { ImportsModule } from './imports/imports.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './core/services/http-interceptor-service';
import { AuthModule } from './auth/auth.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import { CommunicationModule } from './components/communication/communication.module';
import { AllocateUserModule } from './components/allocate-user/allocate-user.module';
import {Ng2TelInputModule} from 'ng2-tel-input';
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BaseModule,
        MaterialModule,
        AppRoutingModule,
        LayoutModule,
        BannerModule,
        InfoCardModule,
        ImportsModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatDividerModule,
        MatDatepickerModule,
        MatStepperModule,
        MatMenuModule,
        MatSnackBarModule,
        HttpClientModule,
        AuthModule,
        CommunicationModule,
        AllocateUserModule,
        Ng2TelInputModule,
    ],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        }, {
            provide: MAT_DATE_LOCALE,
            useValue: 'en-GB'
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
  
}
