import { NgModule } from '@angular/core';
import { CommunicationComponent } from './communication.component';
import { CommunicationService } from './communication.service';
import { MaterialModule } from 'src/app/core/material/material.module';
import { BaseModule } from 'src/app/core/base/base.module';


@NgModule({
    declarations: [CommunicationComponent],
    imports: [
        BaseModule,
        MaterialModule,
    ],
    providers: [
        CommunicationService
    ]
})
export class CommunicationModule { }
