import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sym-tlvt-card',
  templateUrl: './tlvt-card.component.html',
  styleUrls: ['./tlvt-card.component.scss']
})
export class TlvtCardComponent {
  @Input() name: string;
  @Output() buttonClicked = new EventEmitter();
  @Input() hasButton = false;
  @Input() buttonIcon: string;

  onButtonClicked(e) {
    this.buttonClicked.emit(e);
  }

}
