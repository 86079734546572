<div *ngIf="user">
  <button matTooltip="User options" mat-button [matMenuTriggerFor]="userMenu" class="user-button" matBadge="{{notifications}}"
  matBadgePosition="before" matBadgeColor="warn">
    <div fxLayout="row">
      <img class="avatar" src="assets/images/avatars/profile.jpg">
      <span class="username mr-12" fxHide fxShow.gt-sm>{{userName}}</span>
      <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <mat-menu #userMenu="matMenu" xPosition="before">
    <button matTooltip="Notifications" matTooltipPosition="left" mat-menu-item class="menu" (click)="gotoNotifications()" >
      <mat-icon matBadge="{{notifications}}" matBadgeColor="warn" >notification_important</mat-icon>
      <span>Notifications</span>
    </button>
    <mat-divider></mat-divider>
    <button matTooltip="Settings" matTooltipPosition="left" (click)="goToProfileSettings()" mat-menu-item class="menu">
      <mat-icon>account_circle</mat-icon>
      <span>Profile</span>
    </button>
    <mat-divider></mat-divider>
    <button matTooltip="Signout of your account" matTooltipPosition="left" (click)="logout()" mat-menu-item
      class="menu">
      <mat-icon>exit_to_app</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>
</div>