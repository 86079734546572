<mat-card fxLayout="column" [ngClass]="{'small-card-right': smallCardRight,'small-card-left': smallCardLeft, 'decrease-bottom': decreaseBottom}">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <span style="font-size: 28px;">{{title}}</span>
      <button *ngIf="titleButton" (click)="titleButton.method()" mat-button><mat-icon>{{titleButton.icon}}</mat-icon></button>
      <button *ngIf="icon && btnMethod" (click)="onButtonClicked()" mat-button><mat-icon>{{icon}}</mat-icon></button>
    </div>
    <div *ngIf="title" style="padding: 24px 34px;left: 10px;">
      <mat-divider [ngStyle]="{'width': smallDivider}" style="margin: 0 34px;"></mat-divider>
    </div>
    <span>{{subTitle}}</span>
    <ng-content></ng-content>
  </mat-card>