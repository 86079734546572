import { NgModule } from '@angular/core';

import { SearchbarComponent } from './searchbar.component';
import { BaseModule } from 'src/app/core/base/base.module';
import { MaterialModule } from 'src/app/core/material/material.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoadingModule } from '../loading/loading.module';


@NgModule({
  declarations: [SearchbarComponent],
  imports: [
    BaseModule,
    MaterialModule
  ],
  exports: [SearchbarComponent]
})
export class SearchbarModule { }
