import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '../loading/loading.service';
import { UserService } from 'src/app/users/user.service';
import { NotificationsService } from 'src/app/notifications/notifications.service';

@Component({
  selector: 'sym-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.scss']
})
export class ProfileSummaryComponent implements OnInit {
  currentUser: any;
  user: any;
  userName: any;
  userId: any;
  notifications: any;
  constructor(
    private router: Router,
    public loadingservice: LoadingService,
    public userService: UserService,
    public notificationService: NotificationsService,
  ) { }

  ngOnInit() {
    this.notificationService.getNotification.subscribe((data: any) => {
      if(data){
        this.getNotifications();
      }
     })
    this.getUserDetails();
    this.getNotifications();
  }

  logout() {
    this.loadingservice.openBlockingLoader('Logging out', 'primary', 'indeterminate', 0);
    setTimeout(() => {
      this.loadingservice.closeBlockingLoader();
      this.router.navigateByUrl('/login');
      localStorage.clear();
      sessionStorage.clear();
    }, 1000);
    localStorage.clear();
    sessionStorage.clear();
  }

  getUserDetails() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.user = true;
      this.userName = this.currentUser.email;
    }
  }

  getNotifications() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const userId = currentUser.userId;
    this.notificationService.getNotifications(userId).subscribe((res: any) => {
      this.notifications = res.totalNotifications;
      if(this.notifications === '0' || this.notifications === 0) {
        this.notifications = '';
      }
    }, error => {
      this.loadingservice.closeBlockingLoader();
    });
  }

  gotoNotifications() {
    this.notifications = '';
    this.router.navigateByUrl('/notifications');
  }
  
  goToProfileSettings() {
    this.router.navigateByUrl('/user-settings');
  }

}
