import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sym-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() titleButton: any;
  @Input() icon: any;
  @Input() smallDivider: any = '92%';
  @Output() btnMethod = new EventEmitter();
  @Input() smallCardRight: boolean = false;
  @Input() smallCardLeft: boolean = false;
  @Input() decreaseBottom: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  onButtonClicked(e) {
    this.btnMethod.emit(e);
  }

}
